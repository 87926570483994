import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { classroomImg } from './infracarddata';
import Card from 'react-bootstrap/Card';
const PagesCard = ({data}) => {
    return (
        <>

            <Row id='imgrow'>

                {data.map((e, ind) => {

                    return <Col lg="4">

                        <Card  className="first"  style={{boxShadow:" rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px"}} key={ind}>
                            <Card.Img  style={{ height:"30vh"}}variant="top" src={e.img} />

                        </Card>
                    </Col>



                })}




            </Row>

        </>
    )
}

export default PagesCard
