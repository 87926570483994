import React from 'react'

import { useState } from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar/Sidebar'
const Donor = () => {


    const [open, setOpen] = useState(false)
    const showData = (state) => {

        setOpen(!state)

    }
    const [open1, setOpen1] = useState(false)
    const showData1 = (state) => {



        setOpen1(!state)

    }

    console.log(open)
    return (
        <>
            <div className="contentmaincont">

                


                <Sidebar/>



                <div className='aboutus'>


                    <div className='firsttext'>
                    <div className='mandatory_heading1'>Ours Donors</div>


                        <p className='h4' style={{
                            fontSize: "20px",
                            color: "black",
                            fontWeight:"bold"

                        }}>Aadhar Housing Finance Ltd. (Aadhar):</p>
                        <p className='mt-2'>Aadhar Housing Finance Ltd. (Aadhar) has been associated with us as
                            our valued CSR donor for multiple projects. The Hermann Gmeiner
                            Vocational Training Institutes at Nizamuddin, Faridabad, Palla and
                            Anangpur are all generously supported by Aadhar, making it possible for
                            us to carry out transformative endeavours even in the remotest of
                            locations.</p>

                        <p>In their own words- “Aadhar Housing Finance Ltd. (Aadhar) is one of the
                            largest affordable housing finance companies in India servicing the
                            home financing needs of the low income sections of the society. Aadhar
                            endeavours to empower underserved millions to own their first homes.”</p>

                        <p>“Our key CSR initiatives center around this thought of inclusive
                            development and reflects in the diverse activities we undertake, catering
                            to the vast, underserved sections of society. Ranging from health care to
                            skill development, our initiatives not only provide for the weaker sections
                            of society, but also empower them to sustain a better standard of living
                            in the longer term. Our activities are focused primarily on the
                            improvement of health and education.”
                            <p>The skilling programme being run at Hermann Gmeiner Vocational
                                Training Institutes is the skilling initiative by Aadhar, namely <b>“Aadhar
                                    Kaushal”.</b> The programme focusses on actions towards skilling and
                                education of marginalised communities. The association between
                                Aadhar and Hermann Gmeiner Vocational Training Institutes is a
                                seamless amalgamation of our joint aspiration to make underserved
                                youth employable while not only expanding their skill-set but also their
                                mind-set and living standards.</p>

                        </p>
                    </div>



                    <div className='firsttext'>
                        <p style={{
                            fontSize: "20px",
                            color: "black",
                            fontWeight:"bold"

                        }}>DHL Logistics:</p>



                        <p>One of our longest associated CSR Donors that has supported multiple
                            skill training projects including Vocational Training Institute Nizamuddin
                            and Vocational training Institute Raipur. Our years of association with
                            DHL has built strong ties of trust and respect that stand as testimony for
                            our undying dedication to serve the most underserved.
                        </p>

                        <p>DHL is the world’s leading logistics company running multiple divisions,
                            namely, DHL Express, DHL Global Forwarding, DHL Freight, DHL E-

                            Commerce Solutions and DHL Supply Chain. It is a name that stands tall
                            in international communities for revolutionising the world of logistics
                            through decades of quality service and world class delivery solutions.
                        </p>

                        <p>In their own words- “We have always been revolutionizing, shaping, and
                            simplifying the world of logistics. From inventing the international air
                            express industry to becoming the world’s leading logistics company;
                            we’re thinkers, makers and pioneers that constantly challenge what’s
                            possible.”</p>

                        <p>“Social responsibility is an important part of our company's strategy. By
                            implementing our motto &quot;Living Responsibility&quot; we focus on the
                            environment (GoGreen), support (GoHelp) <b>and education (GoTeach)</b>
                            and support the Global Volunteer Day (Living Responsibility Fund).”</p>

                        <p>and support the Global Volunteer Day (Living Responsibility Fund).”
                            “The goal of our group-wide GoTeach program is to improve the
                            employability of young people, especially those from disadvantaged
                            socio-economic backgrounds due to poverty, loss of family and fleeing.”</p>

                        <p>Through its association with SOS Children’s Villages of India, DHL has
                            empowered thousands of lives from underprivileged communities to
                            receive the education and skill training support that they hoped for.</p>

                    </div>






                </div>

            </div>



        </>
    )
}

export default Donor
