import React from 'react'
import Footer from '../../footer/Footer'
import Home from '../../Home/Home'
import Navbar from '../../navbar/Navbar'
import Sidebar from '../../Sidebar/Sidebar'
import { otherData } from '../infracarddata'
import { otherImg } from '../infracarddata'
import PagesCard from '../PagesCard'
const Otherfacilities = () => {
  return (
    <>

    <Navbar/>
    <Home/>

    <div className='contentmaincont'>

        
            <Sidebar />
        


        <div className="aboutus">

            <div className="firstext">
                <div className='mandatory_heading1'>Other Facilities:</div>


          


            </div>
            {otherData.map((e,ind)=>{
                 
                 return<div className="firsttext" key={ind}>


                    <div style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        color: "#009EE0",
                        marginTop:"8px"
                    }}>{e.title}</div>

                    <p>{e.desc}</p>
                    <p>{e.desc1}</p>




                 </div>








            })}

            <PagesCard data={otherImg}/>

        </div>


    </div>

    <div className="mt-5" style={{ background: "#F8F9F9" }}>
        <Footer/>
    </div>

</>
  )
}

export default Otherfacilities
