import React from 'react'
 import Home from "../Home/Home"
import Footer from "../footer/Footer"
import About from './About'

import Navbar from '../navbar/Navbar'

const Abouthome = () => {
  return (
    <>
    <Navbar/>
    <Home/>
    <About/>
    <Footer/>
    
      
    </>
  )
}

export default Abouthome
