import first from "../../images/nizamplacementimg/first.png"
import second from "../../images/nizamplacementimg/second.jpg"
import third from "../../images/nizamplacementimg/third.jpg"
import fourth from "../../images/nizamplacementimg/fourth.png"
import fifth from "../../images/nizamplacementimg/fifth.png"
import six from "../../images/nizamplacementimg/six.png"
import seven from "../../images/nizamplacementimg/seven.jpg"
import eight from "../../images/nizamplacementimg/eight.jpg"

const imgData=[

    {

        img:first,

    },
    {

        img:second,

    },
    {

        img:third,

    },
    {

        img:fourth,

    },
    {

        img:fifth,

    },
    {

        img:six,

    },
    {

        img:seven,

    },
    {

        img:eight,

    },
    {

        img:first,

    },
    {

        img:second,

    },
    {

        img:third,

    },
    {

        img:fourth,

    },
    {

        img:fifth,

    },
    {

        img:six,

    },
    {

        img:seven,

    },
    {

        img:eight,

    },


]



export default imgData