import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";


import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import first from "../../images/Donorslider/1.png"
import second from "../../images/Donorslider/2.png"
import { Pagination, Navigation, Autoplay } from "swiper";

const Homeslide = () => {
    return (
        <>
         <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    loop={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
    
                    <SwiperSlide>
                        <img style={{height:"100%"}} src={first} alt="" />
                       
    
                    </SwiperSlide>
                    <SwiperSlide>
                        <img style={{height:"100%"}} src={second} alt="" />
                       
    
                    </SwiperSlide>
                  
    
    
    
                </Swiper>
          
        </>
      )
}

export default Homeslide
